import React from 'react';

import { MenuLink, MenuWrapper } from './style';

interface MenuItem {
    url: string;
    title: string;
}

const MenuItemWrapper: React.FC<MenuItem> = function MenuItemWrapper({ url, title }) {
    return (
        <li>
            <MenuLink to={url} end>
                {title}
            </MenuLink>
        </li>
    );
};

interface IDefaultLayout {
    pagesList: [string, string][];
}

export const Menu: React.FC<IDefaultLayout> = function Menu({ pagesList }) {
    return (
        <nav>
            <MenuWrapper>
                {pagesList.map(([url, name]) => (
                    <MenuItemWrapper key={url} url={url !== `/` ? `pages${url}` : url} title={name} />
                ))}
            </MenuWrapper>
        </nav>
    );
};
