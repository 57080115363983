import React from 'react';

import { FILES_URL } from '../../constants/api';
import { useGetMainPageDataQuery } from '../../store/services/staticPageApi';
import { Illustration, Text, Title, Wrapper } from './style';

export function MainPage() {
    const { data, isFetching, isError } = useGetMainPageDataQuery();

    if (isFetching) return <Title>Загрузка...</Title>;
    if (isError || !data) return <Title>Ошибка! Попробуйте позже.</Title>;

    return (
        <div>
            <Title>{data.title}</Title>
            <Wrapper>
                <Text dangerouslySetInnerHTML={{ __html: data.content }} />
                <Illustration role="img" aria-label="Иллюстрация" src={`${FILES_URL}${data.logo}`} />
            </Wrapper>
        </div>
    );
}
