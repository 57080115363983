import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ROUTES } from './constants/routes';
import { ScrollToTop } from './helpers/ScrollToTop';
import { DefaultLayout } from './layouts/Default';
import { CoursePage } from './pages/Course';
import { MemberPage } from './pages/Employee';
import { MainPage } from './pages/Main';
import { NotFoundPage } from './pages/NotFound';
import { StaticPage } from './pages/StaticPage/StaticPage';
import { useGetStaticPagesDictionaryQuery } from './store/services/staticPageApi';
import { GlobalStyles } from './styles/global';

export function App() {
    const { data, isFetching, isError } = useGetStaticPagesDictionaryQuery();

    const pagesList = data ? Object.entries(data) : [];
    const main: [string, string] = ['/', 'Главная'];
    const fullPagesList = [main, ...pagesList];

    if (isFetching) return <>Загрузка...</>;
    if (isError || !data) return <>Ошибка! Попробуйте позже.</>;

    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path={ROUTES.MAIN} element={<DefaultLayout pagesList={fullPagesList} />}>
                    <Route index element={<MainPage />} />
                    <Route path={ROUTES.MEMBER_PAGE} element={<MemberPage />} />
                    <Route path={ROUTES.PROGRAM_PAGE} element={<CoursePage />} />
                    <Route path="pages/:name" element={<StaticPage />} />
                    <Route path={ROUTES.DRAFT_PAGE} element={<StaticPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            </Routes>
            <GlobalStyles />
        </>
    );
}
