import React from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from '../../components/widgets/Footer';
import { Header } from '../../components/widgets/Header';
import { Menu } from '../../components/widgets/Menu';
import { ContentWrapper, DefaultLayoutStyle } from './style';

interface IDefaultLayout {
    pagesList: [string, string][];
}

export const DefaultLayout: React.FC<IDefaultLayout> = function DefaultLayout({ pagesList }) {
    return (
        <DefaultLayoutStyle>
            <Header />
            <ContentWrapper>
                <Menu pagesList={pagesList} />
                <Outlet />
            </ContentWrapper>
            <Footer />
        </DefaultLayoutStyle>
    );
};
