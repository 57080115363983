import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL, Endpoint, MainPageData } from '../../constants/api';

export const mainPageApi = createApi({
    reducerPath: 'mainPageApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, timeout: 5000 }),
    endpoints: (builder) => ({
        getMainData: builder.query<MainPageData, void>({
            query: () => `${Endpoint.Main}`,
        }),
    }),
});

export const { useGetMainDataQuery } = mainPageApi;
