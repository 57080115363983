import React from 'react';
import { useParams } from 'react-router-dom';

import { getFileUrl } from '../../api';
import { Document, Documents } from '../../components/widgets/Documents';
import { TextBlock } from '../../components/widgets/TextBlock';
import { useGetStaticPageDataQuery } from '../../store/services/staticPageApi';

function getDraftToken(): string | null {
    const { href } = window.location;
    const url = new URL(href);
    const params = new URLSearchParams(url.search);

    return params.get('draft_token');
}

export const StaticPage = function StaticPage() {
    const { name } = useParams();
    const draftToken = getDraftToken() || '';

    const { data, isError, isFetching } = useGetStaticPageDataQuery(
        { path: name || '', draftToken: draftToken || null },
        {
            skip: !name,
        }
    );

    if (isFetching) return <>Загрузка...</>;
    if (isError || !data) return <>Ошибка! Попробуйте позже.</>;

    return (
        <div>
            <TextBlock title={data.title}>
                <div dangerouslySetInnerHTML={{ __html: data.content }} />
            </TextBlock>
            {!!data.attachedFiles.length && (
                <Documents>
                    {data.attachedFiles.map(({ fileName, title }) => (
                        <Document key={title} name={title} download_url={getFileUrl(fileName)} />
                    ))}
                </Documents>
            )}
        </div>
    );
};
