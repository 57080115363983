import React from 'react';

import { TextBlockStyle } from './style';

interface TextBlockProps {
    children: React.ReactNode;
    title?: string;
}

export const TextBlock: React.FC<TextBlockProps> = function TextBlock({ children, title }) {
    return (
        <div>
            {title && <h1>{title}</h1>}
            <TextBlockStyle>{children}</TextBlockStyle>
        </div>
    );
};
