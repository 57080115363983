import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL, Endpoint, MainPageData, PageData } from '../../constants/api';

type StaticPageResponse<T> = {
    totalCount: number;
    data: T;
    status: 200;
};

type StaticPageErrorResponse = {
    status: number;
    statusMsg: string;
};

type StaticPageDictionaryItem = {
    path: string;
    name: string;
};

type StaticPageDictionary = Record<string, string>;

function isSuccess<T>(response: StaticPageResponse<T> | StaticPageErrorResponse): response is StaticPageResponse<T> {
    return (response as StaticPageResponse<T>).data !== undefined;
}

function transfromStaticPageResponse<T>(response: StaticPageResponse<T> | StaticPageErrorResponse) {
    if (isSuccess(response)) {
        return response.data;
    }

    throw new Error(`Статус: ${response.status}. Сообщение: ${response.statusMsg}`);
}

export const staticPageApi = createApi({
    reducerPath: 'staticPageApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['Pages'],
    endpoints: (builder) => ({
        getStaticPagesDictionary: builder.query<StaticPageDictionary, void>({
            query: () => `${Endpoint.StaticPagesDictionary}/`,
            providesTags: ['Pages'],
            transformResponse: (response: StaticPageResponse<StaticPageDictionaryItem[]> | StaticPageErrorResponse) => {
                if (isSuccess(response)) {
                    return response.data.reduce(
                        (acc, el) => ({
                            ...acc,
                            [el.path]: el.name,
                        }),
                        {}
                    );
                }

                throw new Error(`Статус: ${response.status}. Сообщение: ${response.statusMsg}`);
            },
        }),
        getMainPageData: builder.query<MainPageData, void>({
            query: () => `${Endpoint.Main}`,
            transformResponse: transfromStaticPageResponse<MainPageData>,
        }),
        getStaticPageData: builder.query<PageData, { path: string; draftToken: string | null }>({
            query: ({ path, draftToken }) => ({
                url: `${Endpoint.StaticPage}/${path}${draftToken ? `?draft_token=${draftToken}` : ''}`,
            }),
            transformResponse: transfromStaticPageResponse<PageData>,
        }),
        getDraftPage: builder.query<PageData, { path: string; draftToken: string }>({
            query: ({ path, draftToken }) => `${Endpoint.StaticPage}${path}?draft_token=${draftToken}`,
            transformResponse: transfromStaticPageResponse<PageData>,
        }),
    }),
});

export const {
    useGetStaticPagesDictionaryQuery,
    useGetStaticPageDataQuery,
    useGetDraftPageQuery,
    useGetMainPageDataQuery,
} = staticPageApi;
