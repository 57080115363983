import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getFileUrl, useCourse } from '../../api';
import { Document, Documents } from '../../components/widgets/Documents';

function CourseContent() {
    const { id } = useParams();
    const { data: course, isLoading } = useCourse(Number(id));

    if (isLoading) return <>Загрузка...</>;

    return (
        <div>
            <h1>Курс: {course?.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: course?.content || '' }} />
            <Documents>
                {course?.attachedFiles.map((file) => (
                    <Document name={file.title} download_url={getFileUrl(file.fileName)} key={file.fileName} />
                ))}
            </Documents>
        </div>
    );
}

export function CoursePage() {
    const { id } = useParams();
    const [isCourseIdValid, setIsCourseIdValid] = useState(false);

    useLayoutEffect(() => {
        if (id && !Number.isNaN(Number(id))) {
            setIsCourseIdValid(true);
        }
    }, [id]);

    if (isCourseIdValid === false) {
        return <>Курс не найден</>;
    }

    return <CourseContent />;
}
