import styled from 'styled-components';

export const Wrapper = styled.div`
    display: block;
`;

export const Text = styled.p`
    font-weight: 500;
    font-size: 1.5rem;
`;

export const Photo = styled.img`
    width: auto;
    height: auto;

    max-width: 100%;
    max-height: auto;
`;
