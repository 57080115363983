export const BASE_URL = '/';

export enum Endpoint {
    Main = 'api/v1/pages_info/main_page',
    StaticPage = 'api/v1/pages_info',
    StaticPagesDictionary = 'api/v1/pages',
    Team = 'api/v1/employee/',
    Course = 'api/v1/studyProgram/',
}

export const FILES_URL = '/files/';

export type MainPageData = {
    title: string;
    content: string;
    logo: string;
};

export type UserFileRequest = {
    title: string;
    fileName: string;
};

export type UserFile = {
    id: number;
    title: string;
    fileName: string;
};

export type PageData = {
    url: string;
    name: string;
    title: string;
    content: string;
    attachedFiles: UserFileRequest[];
};

export type ServerErrorResponse = {
    status: number;
    statusText: string;
};

export type ServerPageData = {
    url: string;
    name: string;
    title: string;
    content: string;
    attachedFiles: UserFile[];
};
