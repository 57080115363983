/* eslint-disable prefer-const */
import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTeamMember } from '../../api';
import { FILES_URL } from '../../constants/api';
import { Photo, Text, Wrapper } from './style';

function MemberContent() {
    const { id } = useParams();
    let { data: member, isLoading } = useTeamMember(Number(id));

    if (isLoading) return <>Загрузка...</>;
    if (!member) return <>Ошибка, попробуйте позже!</>;

    return (
        <div>
            <h1>
                Сотрудник: {member.firstName} {member.lastName} {member.patronymic}
            </h1>
            <Wrapper>
                <Text> Должность: {member.position} </Text>
                <Photo aria-label="Фотография сотрудника" src={`${FILES_URL}${member.photo}`} />
            </Wrapper>
            <div dangerouslySetInnerHTML={{ __html: member.info || '' }} />
        </div>
    );
}

export function MemberPage() {
    const { id } = useParams();
    const [isMemberIdValid, setIsMemberIdValid] = useState(false);

    useLayoutEffect(() => {
        if (id && !Number.isNaN(Number(id))) {
            setIsMemberIdValid(true);
        }
    }, [id]);

    if (isMemberIdValid === false) {
        return <>Сотрудник не найден</>;
    }

    return <MemberContent />;
}
