import styled from 'styled-components';

export const Title = styled.h1`
    font-size: 104px;
    font-weight: 800;
    margin-bottom: 42px;
    line-height: 1.1;
    margin-top: 24px;
`;

export const Wrapper = styled.div`
    display: flex;
`;

export const Text = styled.article`
    font-weight: 500;
    font-size: 1.5rem;
`;

export const Illustration = styled.div<{ src: string }>`
    content: '';
    width: 100%;
    height: 100%;
    max-width: 500px;
    min-height: auto;
    min-width: calc(760px * 0.5);
    display: block;
    aspect-ratio: 917 / 760;

    background: url(${(props) => props.src}) no-repeat center / contain;
`;
